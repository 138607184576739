export const documents = [
	{
		title: "Հաշվետվություն 2024",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2024/2024_1_Real_Time.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2024/2024_2_Real_Time.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2024/2024_3_Real_Time.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2024/2024_4_Real_Time.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2023",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2023/2023_1_Real_Time.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2023/2023_2_Real_Time.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2023/2023_3_Real_Time.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2023/2023_4_Real_Time.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2022",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2022/1-ին եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2022/2-րդ եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2022/3-րդ եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2022/2022_4_Real_Time.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2021",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2021/1-ին եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2021/2-րդ եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2021/3-րդ եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2021/4-րդ եռամսյակ.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2020",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2020/1-ին եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2020/2-րդ եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2020/3-րդ եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2020/4-րդ եռամսյակ.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2019",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2019/1-ին եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2019/2-րդ եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2019/3-րդ եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2019/4-րդ եռամսյակ.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2018",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2018/1-ին Եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2018/2-րդ եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2018/3-րդ եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2018/4-րդ եռամսյակ.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2017",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2017/I Եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2017/II Եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2017/III Եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2017/IV Եռամսյակ.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2016",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2016/I Եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2016/II Եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2016/III Եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2016/IV Եռամսյակ.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2015",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2015/I Եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2015/II Եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2015/III Եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2015/IV Եռամսյակ.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2014",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2014/I Եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2014/II Եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2014/III Եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2014/IV Եռամսյակ.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2013",
		reports: true,
		documents: [
			{title: "1-ին եռամսյակ", file: "http://realtime.am/docs/2013/I Եռամսյակ.pdf"},
			{title: "2-րդ եռամսյակ", file: "http://realtime.am/docs/2013/II Եռամսյակ.pdf"},
			{title: "3-րդ եռամսյակ", file: "http://realtime.am/docs/2013/III Եռամսյակ.pdf"},
			{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2013/IV Եռամսյակ.pdf"},
		],
	},
	{
		title: "Հաշվետվություն 2012",
		reports: true,
		documents: [{title: "4-րդ եռամսյակ", file: "http://realtime.am/docs/2012/IV Եռամսյակ.pdf"}],
	},
	{
		title: "Փաստաթղթեր",
		reports: false,
		documents: [
			{
				title: "Հրապարակային պայմանագիր",
				file: "http://realtime.am/docs/new_documents/Oferta.pdf",
			},
			// {
			// 	title: "Ընդհանուր դրույթներ",
			// 	file: "http://realtime.am/docs/new_documents/terms.pdf",
			// },
		],
	},
	{
		title: "Հին փաստաթղթեր",
		reports: false,
		documents: [
			{title: "Հրապարակային պայմանագիր", file: "http://realtime.am/docs/old_documents/Oferta.pdf"},
			{
				title: "Ծառայությունների ակտիվացման հավելված",
				file: "http://realtime.am/docs/old_documents/havelvac.pdf",
			},
			{
				title: "Իրավաբանական անձանց համար",
				file: "http://realtime.am/docs/old_documents/iravaban.pdf",
			},
			{
				title: "Տարեկան բաժանորդագրություն-wifi",
				file: "http://realtime.am/docs/old_documents/tarekan-wifi.pdf",
			},
			{
				title: "Տարեկան բաժանորդագրություն-կրկնակի",
				file: "http://realtime.am/docs/old_documents/tarekan-krknaki.pdf",
			},
			{
				title: "Հավելված 1 օպտիկամանրաթելային ցանցի համար",
				file: "http://realtime.am/docs/old_documents/price-2018-01.03.18-optica.pdf",
			},
			{
				title: "Հավելված 2 ռադիոմոդեմային ցանցի համար",
				file: "http://realtime.am/docs/old_documents/price-2018-01.03.18.pdf",
			},
			{
				title: "Հավելված 3 - սարքերի հանձնման - ընդունման ակտ",
				file: "http://realtime.am/docs/old_documents/havelvac3-wifi.pdf",
			},
		],
	},
];
